<template>
  <div>
    <v-app-bar class="pr-5" color="#FFFFFF" app>
      <v-app-bar-nav-icon class="d-flex d-sm-none" @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title class="mainToolbar-title">
        {{ barTitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="showSwitchCampaignButton && campaignData.no_of_campaign > 1 && campaignData.campaign_type=='school'"
        depressed
        color="#F2F2F2"
        class="switchCampaignBtn mr-5"
        @click="toggleSelectCampaignModal({ show: true })"
      >
        <v-icon color="#6B6B6B" size="20" left>mdi-repeat</v-icon>
        <span class="mr-2" style="color: #383838">Switch Campaign</span>
        <v-icon color="#6B6B6B" size="25" right>mdi-chevron-down</v-icon>
      </v-btn>
      <v-btn
        outlined
        depressed
        color="#38227A"
        class="switchCampaignBtn mr-5 rounded-lg"
        v-if="
          userTeam === 'admin' &&
          ($route.matched[0].path.substring(1) === !'teacher' ||
            $route.matched[0].path.substring(1) === !'pe-teacher')
        "
        @click="editCampaign"
      >
        <v-icon class="mt-1" size="18" left>mdi-border-color</v-icon>
        Edit Campaign
      </v-btn>
       <v-btn
              color="#38227A"
              dark
              class="px-12 text-capitalize"
              height="45px"
               width="155px"
               @click="route1()"
              style="border-radius: 10px"
              v-if="$vuetify.breakpoint.smAndUp">
              <span>Upload Documents</span>
            </v-btn>
            <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list>
                <v-list-item-group active-class="active-class">
                  <v-list-item @click="route1">
                    <v-list-item-title> Upload Documents </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
      <!--<v-btn
        outlined
        depressed
        color="#38227A"
        class="switchCampaignBtn mr-5 rounded-lg"
        v-if="$route.matched[0].path.substring(1) === 'teacher' ||
            $route.matched[0].path.substring(1) === 'pe-teacher'"
        @click="toggleAddEditViewTeacherModal({show:true,id:organizationData.id})"
      >
      <v-icon left>mdi-pencil</v-icon>
      <span>Edit public page</span>
      </v-btn>-->
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon x-large v-on="on">
            <v-avatar color="rgba(255, 81, 81, 0.12)" size="38">
              <span class="user-initials-text">{{ user.initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="rgba(255, 81, 81, 0.12)">
                <span class="user-initials-text">{{ user.initials }}</span>
              </v-avatar>
              <h3>{{ user.user_name }}</h3>
              <p class="text-caption mt-1">
                {{ user.user_email }}
              </p>
              <!-- <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded text> Edit Account </v-btn> -->
              <v-divider class="my-3"></v-divider>
              <v-btn depressed rounded @click="logoutClickHandler" text>
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <!--desktop view nav drawer-->
    <v-navigation-drawer
      app
      dark
      color="#2C1963"
      width="245px"
      height="100%"
      permanent
      hide-overlay
      style="box-shadow: 9px 4px 8px rgba(0, 0, 0, 0.08)"
      class="d-none d-sm-flex"
    >
      <div v-if="loader">
        <v-sheet :dark="false" color="#ffffff">
          <v-skeleton-loader
            class="mx-auto"
            height="310"
            max-width="300"
            boilerplate
            type="image"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
      <div
        class="pt-6 "
        style="
          text-align: -moz-center;
          text-align: -webkit-center;
          background: #ffffff;
          min-height: 310px;
        "
        v-else
      >
        <v-img
          v-if="this.organizationData.logo_url !== null"
          max-width="125"
          :src="this.organizationData.logo_url"
        />
        <v-avatar v-else color="rgba(255, 81, 81, 0.12)" size="135">
          <span class="orgLogo-initials-text">{{
            getInitials(organizationData.name)
          }}</span>
        </v-avatar>
        <div
          class="schoolName text-center my-3"
          @click="viewPublicDonationPageHandler(campaign)"
        >
          {{ organizationData.name }}
        </div>
        <v-divider
          style="border: 1px dashed rgba(0, 0, 0, 0.3) !important"
        ></v-divider>
        <div class="text-center pt-3 pb-5">
          <v-chip :class="`${campaignData.campaign_status.toUpperCase()}`">
            <span>{{ campaignData.campaign_status }} Campaign</span>
          </v-chip>
          <div class="campaignDates">
            {{ campaignData.start_date }} to {{ campaignData.end_date }}
          </div>
          <div class="campaignId">{{ campaignData.campaign_id }}</div>
        </div>
      </div>
      <v-list class="mt-2 pa-0" nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            class="justify-center"
            active-class="active-class"
            v-for="(item, i) in navLinks"
            :key="i"
            @click="ChangeTab(item.heading, item.select)"
            :to="{ name: item.to, query: queryParams }"
            link
            exact
          >
            <template v-slot:default="{ active }">
              <div style="width: 70%">
                <img
                  style="max-width: 15px; margin-bottom: -2px"
                  class="display-inline"
                  :src="
                    getImgUrl(
                      active ? `${item.icon}Active.svg` : `${item.icon}.svg`
                    )
                  "
                />
                <v-list-item-title
                  class="flex-zero display-inline ml-6 font-roboto-slab"
                >
                  {{ item.title }}
                </v-list-item-title>
              </div>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!--mobile view nav drawer-->
    <v-navigation-drawer
      app
      v-model="drawer"
      dark
      color="#2C1963"
      width="290px"
      height="100%"
      temporary
      hide-overlay
      style="box-shadow: 9px 4px 8px rgba(0, 0, 0, 0.08)"
      class="d-flex d-sm-none"
    >
      <div v-if="loader">
        <v-sheet color="grey darken-2 lighten-4" class="pa-3">
          <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card"
          ></v-skeleton-loader>
        </v-sheet>
      </div>
      <div
        class="pt-6"
        style="
          text-align: -moz-center;
          text-align: -webkit-center;
          background: #ffffff;
        "
        v-else
      >
        <v-img max-width="189" :src="organizationData.logo_url" style="" />
        <div
          class="schoolName px-4 my-3"
          @click="viewPublicDonationPageHandler(campaign)"
        >
          {{ organizationData.name }}
        </div>
        <v-divider
          style="border: 1px dashed rgba(0, 0, 0, 0.3) !important"
        ></v-divider>
        <div class="pt-3 pb-5">
          <v-chip :class="`${campaignData.campaign_status.toUpperCase()}`">
            <span>{{ campaignData.campaign_status }} Campaign</span>
          </v-chip>
          <div class="campaignDates">
            {{ campaignData.start_date }} to {{ campaignData.end_date }}
          </div>
          <div class="campaignId">{{ campaignData.campaign_id }}</div>
        </div>
      </div>
      <v-list class="pa-0" nav dense>
        <v-list-item-group>
          <v-list-item
            class="justify-center"
            active-class="active-class"
            v-for="(item, i) in navLinks"
            :key="i"
            @click="ChangeTab(item.heading, item.select)"
            :to="{ name: item.to, query: queryParams }"
            link
            exact
          >
            <template v-slot:default="{ active }">
              <div style="width: 70%">
                <img
                  max-width="19"
                  class="mb-n1 display-inline"
                  :src="
                    getImgUrl(
                      active ? `${item.icon}Active.svg` : `${item.icon}.svg`
                    )
                  "
                />
                <v-list-item-title
                  class="flex-zero display-inline ml-10 font-roboto-slab"
                  >{{ item.title }}</v-list-item-title
                >
              </div>
            </template>
          </v-list-item>
          <!-- <v-list-item class="d-sm-none">
            <v-icon>mdi-logout</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: auto; background: #f7f7f7">
      <router-view></router-view>
    </v-main>
    <select-campaign-modal @reload="loadData"></select-campaign-modal>
    <teacher-edit-modal @reload="relaodPage"></teacher-edit-modal>
    <update-district-modal @reload="relaodPage"></update-district-modal>
    <update-school-modal @reload="relaodPage"></update-school-modal>
  </div>
</template>

<script>
import { ROUTER_URL } from "@/constants/urls";
import authToken from "@/common/authToken";
import { mapActions, mapGetters } from "vuex";
import {
  SALES_REPRESENTATIVE,
  TEACHER,
  PRINCIPAL,
} from "@/constants/ModuleKeys";
import Helper from "@/helper";
import { CAMPAIGN_ID } from "@/constants/APIKeys";
// import _ from "lodash";
export default {
  name: "CampaignLayout",
  components: {
    SelectCampaignModal: () =>
      import("@/components/SelectCampaignModal/SelectCampaignModal.vue"),
    TeacherEditModal: () =>
      import("@/components/TeacherUpdateModal/TeacherUpdateModal"),
    UpdateDistrictModal: () =>
      import("@/components/DistrictUpdateModal/DistrictUpdateModal"),
    UpdateSchoolModal: () =>
      import("@/components/SchoolUpdateModal/SchoolUpdateModal"),
  },
  data() {
    return {
    subAdminAccess:localStorage.getItem("subadminAccess"),
      user: {},
      selectedItem: -1,
      navLinks: [
       {
          title: "School Info",
          icon: "team",
          heading: " Info",
          key: "todolist",
          permission: ["school"],
          to: "",
        },
         {
          title: "Onboarding",
          icon: "team",
          heading: "Fill Out School Info ",
          key: "hosttodolist",
          permission: ["school"],
          to: "",
        },
         {
          title: "Resources",
          icon: "sponsor",
          heading: "Resource Management",
          key: "resource",
          permission: ["school"],
          to: "",
        },
        {
          title: "Dashboard",
          icon: "dashboard",
          heading: " Campaign Dashboard",
          key: "dashboard",
          permission: [
            "district",
            "school",
            "teacher",
            "pe-teacher",
            "principal",
          ],
          to: "",
        },
        {
          title: "Schools",
          icon: "school",
          heading: "School Campaigns",
          key: "schoolManagement",
          permission: ["district"],
          to: "",
        },
        {
          title: "Teachers",
          icon: "teacher",
          heading: "Teacher Management",
          key: "teacherManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Students",
          icon: "student",
          heading: "Student Management",
          key: "studentManagement",
          permission: ["district", "school", "teacher"],
          to: "",
        },
        {
          title: "Sponsors",
          icon: "sponsor",
          heading: "Sponsor Management",
          key: "sponsorManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Donation",
          icon: "donation",
          heading: "Donation Management",
          key: "donationManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Committee",
          icon: "team",
          heading: "Committee Management",
          key: "teamManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Emails",
          icon: "email",
          heading: "Emails",
          key: "emailSettings",
          permission: ["district", "school"],
          to: "",
        },

        {
          title: "Gallery",
          icon: "team",
          heading: "Gallery Management",
          key: "galleryManagement",
          permission: ["teacher", "school"],
          to: "",
        },
       
       
           {
          title: "Calendar",
          icon: "calendars",
          heading: "Calendar Management",
          key: "campaigncalendar",
          permission: ["school"],
          to: "",
        },
       {
          title: "Need Help",
          icon: "email",
          heading: "Need Help",
          key: "needhelp",
          permission: ["school","teacher","district"],
          to: "",
        },
    /**     {
          title: "Game",
          icon: "campaign",
          heading: "CampaignManagement Management",
          key: "campaignGame",
          permission: ["school"],
          to: "",
        },*/
      ],
      drawer: false,
      barTitle: "Campaign Dashboard",
      selected: "Dashboard",
      districtData: {},
      campaignData: {},
      campaignListLength: -1,
      loader: true,
    };
  },
  created() {
    this.$root.$refs.campaignLayout = this;
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    orgLogoUrl() {
      return this.organizationData.logo_url;
    },
    currentRouteName() {
      return this.$route.name;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    campaign() {
      return this.selectedCampaignData.campaignData;
    },
    showSwitchCampaignButton() {
      switch (this.$route.name) {
        case ROUTER_URL.districtPanel.children.dashboard.name:
          return true;
        case ROUTER_URL.schoolPanel.children.dashboard.name:
          return true;
        case ROUTER_URL.teacherPanel.children.dashboard.name:
          return true;
        default:
          return false;
      }
    },
    routeQuery() {
      return this.$route.query;
    },
    queryParams() {
      let item = {};
      let USER_PANEL = this.$route.matched[0].path.substring(1);
      if (this.$route.query[CAMPAIGN_ID])
        item[CAMPAIGN_ID] = this.$route.query[CAMPAIGN_ID];
      if (this.$route.query[USER_PANEL])
        item[USER_PANEL] = this.$route.query[USER_PANEL];
      else if (
        this.$route.matched[0].path.substring(1) === "pe-teacher" ||
        this.$route.matched[0].path.substring(1) === PRINCIPAL
      )
        item[TEACHER] = this.$route.query["teacher"];
      return item;
    },
  },
  watch: {
    currentRouteName(val) {
      if (val === ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name) {
        this.barTitle = "Upload Teacher in Bulk";
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSelectCampaignModal: "campaign/toggleModal",
      selectCampaignAction: "campaign/updateSelectedCampaign",
      toggleAddEditViewTeacherModal: "teacherManagement/toggleTeacher",
    }),
    relaodPage() {
      this.$router.go();
    },
    getImgUrl(img) {
      return require("@/assets/Navigation/" + img);
    },
    route() {
      let linkToBeChange =
        ROUTER_URL.schoolPanel.children.teacherManagement.name;
      let NewLink = ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name;
      let key = ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name;
      let heading = "Upload Teacher in bulk";
      this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
      this.$router.push({
        name: NewLink,
        query: this.queryParams,
      });
    },
    navLinksManipulation(linkToBeChange, NewLink, key, heading) {
      this.selectedItem = this.navLinks.findIndex(
        (item) => item.key === linkToBeChange
      );
      this.navLinks[this.selectedItem] = NewLink;
      // this.navLinks[this.selectedItem].key = key;
      // this.navLinks[this.selectedItem].heading = heading;
      console.log(key, heading);
    },
    /**
     * Logout button click handler
     */
    logoutClickHandler() {
      authToken.removeAccessToken();
      this.$router.push({ name: ROUTER_URL.auth.children.login.name });
      this.clearData();
    },
    /**
     * for changing the tabs and heading
     * param {string} title - navbar heading
     */
    ChangeTab(heading, select) {
      this.barTitle = heading;
      this.drawer = false;
      this.selected = select;
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setNavigationBarData(userType) {
      let USER_PANEL = Helper.getUserPanel(userType);
      //permission check and route
      this.navLinks = this.navLinks.filter((nav) => {
        if (nav.permission.includes(userType)) {
          let COMPONENT = nav.key;
          nav.to = ROUTER_URL[USER_PANEL].children[COMPONENT]["name"];
          return nav;
        }
      });
      //setting up Dashboard title
      this.navLinks[0].heading =
        this.capitalizeFirstLetter(userType) + this.navLinks[0].heading;
      this.ChangeTab(this.navLinks[0].heading, this.navLinks[0]);
      // if user is a SALES REP have to remove teams nav-link
      if (this.user.user_type === SALES_REPRESENTATIVE) {
        let removeLinks = ["teamManagement"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
       if (this.user.user_type === 'game') {
        let removeLinks = ["todolist"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
       if (this.user.user_type === 'school') {
        let removeLinks = ["todolist"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
    },
    loadData() {
      this.organizationData = this.selectedCampaignData.organizationData;
      this.campaignData = this.selectedCampaignData.campaignData;
      this.loader = false;
    },
    clearData() {
      this.selectCampaignAction({});
    },
    editCampaign() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: this.selectedCampaignData.campaignData.id },
      });
    },
    viewPublicDonationPageHandler(item) {
      const { sub_domain, campaign_type } = item;
      const id = this.organizationData.id;
      const PANEL = this.$route.matched[0].path.substring(1);
      const queryParam = PANEL + "=" + id;
      console.log(sub_domain);
      if (
        PANEL === "district" ||
        (PANEL === "school" && campaign_type === "school")
      ) {
        Helper.openPublicDonationPage(sub_domain);
      } else {
        Helper.openPublicDonationPage(sub_domain, queryParam);
      }
      // Helper.openPublicDonationPage(sub_domain,queryParam);
    },
       route1() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
  },
  mounted() {
    this.user = { ...authToken.decodedToken() };
    this.setNavigationBarData(this.$route.matched[0].path.substring(1));
    let initials = this.getInitials(this.user.user_name);
    this.user = { ...this.user, initials };
    this.selectedItem = this.navLinks.findIndex(
      (item) => item.key === this.currentRouteName
    );
    this.toggleSelectCampaignModal({ show: true });
  },
};
</script>

<style scoped>
.switchCampaignBtn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.switchCampaignBtn >>> span {
  font-family: Lato;
  letter-spacing: 0px;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 315%;
}
.mainToolbar-title {
  font-family: LubalGraph Bd BT;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 315%;
  color: #2d2d2d;
  white-space: nowrap;
}
.campaignStatus >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 161.5%;
}

.ongoing {
  background: #fcede0 !important;
}
.ongoing >>> span {
  color: #e76f00 !important;
}
.upcoming {
  background-color: rgba(0, 133, 255, 0.12) !important;
}
.upcoming >>> span {
  color: #0085ff !important;
}
.completed {
  background-color: rgba(6, 118, 5, 0.12) !important;
}
.completed >>> span {
  color: #067605 !important;
}
.campaignDates {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #1d1d1d;
  margin-top: 5px;
}
.campaignId {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  color: rgba(29, 29, 29, 0.6);
}
.flex-zero {
  flex: none;
}
.display-inline {
  display: inline;
}
.font-roboto-slab {
  font-family: Roboto Slab !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 300% !important;
  color: #9387b6 !important;
}
.active-class {
  border-right: 6px solid #ffba00;
  width: 100%;
  border-radius: 0px !important;
}
.active-class >>> .v-list-item__title {
  color: #ffffff !important;
}
* >>> .theme--dark.v-list-item--active::before {
  opacity: 0.14;
  border-radius: 0px;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}
.orgLogo-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}

.tabs {
  margin-left: 100px;
}
.schoolName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 127%;
  color: #2c1963;
  cursor: pointer;
}
span.v-chip.ONGOING {
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
}
span.v-chip.UPCOMING {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
}
span.v-chip.COMPLETED {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
}
@media (min-width: 0px) and (max-width: 600px) {
  .tabs {
    margin-left: 0px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
